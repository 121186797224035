<template>
  <div v-if="authUserIsAdmin">
    <LoadingMessage v-if="isLoading" :message="'Fetching items...'"></LoadingMessage>

    <ErrorMessage v-if="error" :error="error" class="my-3"></ErrorMessage>

    <form
      @submit.prevent
      v-if="!isLoading && !error"
      class="d-flex align-items-center bg-dark p-2 rounded"
    >
      <div class="form-floating-cta-container">
        <div class="h5 m-0 px-2 text-white">Entries</div>
      </div>
      <IconButton
        @click="refresh"
        :icon="'sync-alt'"
        class="btn btn-primary ms-2"
      ></IconButton>
    </form>

    <div v-if="!isLoading && !error" class="rounded border bg-white mt-2">
      <div class="table-responsive">
        <table class="table m-0">
          <thead>
            <tr>
              <th scope="col" class="align-baseline">Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!items.length">
              <td>No entries found</td>
            </tr>
            <tr v-else v-for="(item, itemIdx) in items" :key="`key-${itemIdx}`">
              <td>
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 me-3">{{ item.log }}</div>
                  <div class="smaller text-muted">{{ formatDateTime(item.createdAt) }}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="!isLoading && !error" class="mt-5 text-center">
      <SubmitButton
        @click="fetchMore"
        v-if="page > 0"
        :text="'Load more'"
        :textBusy="'Please wait...'"
        :isLoading="isLoadingMore"
        :disabled="isLoadingMore"
        class="btn btn-primary"
      ></SubmitButton>
      <div v-else>You have reached the end.</div>
    </div>

    <div v-if="!isLoading && !error" class="mt-5 text-center">
      <SubmitButton
        @click="upgradeDB"
        :text="'Upgrade DB'"
        :textBusy="''"
        :isLoading="isUpgrading"
        :disabled="isUpgrading"
        class="btn btn-lg btn-outline-danger w-100"
      ></SubmitButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { httpGet, httpPost } from '@/core/http';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default {
  name: 'SyslogList',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsAdmin']),
  },
  methods: {
    refresh() {
      this.fetch();
    },
    async fetch() {
      this.isLoading = true;
      this.error = null;
      this.page = 1;
      this.items = [];
      try {
        const res = await httpGet(`/admin/syslog/${this.page}`);
        this.addItems(res.data);
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    async fetchMore() {
      this.isLoadingMore = true;
      try {
        const res = await httpGet(`/admin/syslog/${this.page}`);
        this.addItems(res.data);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoadingMore = false;
      }
    },
    addItems(newItems) {
      newItems.forEach((item) => {
        this.items.push(item);
      });
      if (newItems.length) {
        this.page += 1;
      } else {
        this.page = -1;
      }
    },
    formatDateTime(dt) {
      return dayjs.utc(dt).local().format('lll');
    },
    async upgradeDB() {
      try {
        this.isUpgrading = true;
        await httpPost('/admin/upgradedb');
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isUpgrading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingMore: false,
      isUpgrading: false,
      error: null,
      page: 1,
      items: [],
    };
  },
  mounted() {
    this.fetch();
  },
};
</script>
